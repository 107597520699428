// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import mapAccounts from "./mapAccounts";
import auth from "./authentication";
import notifications from "./notifications";
import todo from "@src/views/apps/todo/store";
import coa from "@src/views/apps/accounts/coa/store";
import users from "@src/views/apps/user/store";
import fileManager from "@src/views/apps/file-system/store";
import attachmentManager from "@src/views/apps/attachments-file-system/store";
import invoice from "@src/views/apps/invoice/store";
import journal from "@src/views/apps/journal/store";
import journalRecurrence from "@src/views/apps/journal-recurrence/store";
import demands from "@src/views/apps/stocks/demands/store";
import demand from "@src/views/apps/stocks/demand/store";
import purchaseRequests from "@src/views/apps/purchases/purchase-request/store";
import TenantCurrencies from "@src/views/apps/currency/store";
import expense from "@src/views/apps/purchases/expense/store";
import vendorInvoice from "@src/views/apps/purchases/vendor-invoice/store";
import recurringBill from "@src/views/apps/purchases/recurring-bill/store";
import recurringExpense from "@src/views/apps/purchases/recurring-expense/store";
import taxTypes from "@src/views/settings/tax-types/store";
import areas from "@src/views/settings/areas/store";
import purchaseOrders from "@src/views/apps/purchases/purchase-orders/store";
import GRN from "@src/views/apps/purchases/goods-received-note/store";
import GRNReturn from "@src/views/apps/purchases/goods-received-note-return/store";
import returnOutwards from "@src/views/apps/purchases/return-outwards/store";
import returnInwards from "@src/views/apps/sales/return-inwards/store";
import customerInvoice from "@src/views/apps/sales/customer-invoice/store";
import deliveryNotes from "@src/views/apps/sales/delivery-note/store";
import customerRecurrenceInvoice from "@src/views/apps/sales/recurrence-invoice/store";
import customerEstimate from "@src/views/apps/sales/estimates/store";
import payments from "@src/views/apps/payments/store";
import inventoryItems from "@src/views/apps/inventory/items/store";
import inventoryBrands from "@src/views/apps/inventory/brands/store";
import inventoryColors from "@src/views/apps/inventory/colors/store";
import inventoryCostCenters from "@src/views/apps/inventory/cost-centers/store/index.js";
import inventoryDepartments from "@src/views/apps/inventory/departments/store";
import creditTerms from "@src/views/apps/credit-terms/store";
import posTills from "@src/views/apps/pos-tills/store";
import posShifts from "@src/views/apps/pos-shifts/store";
import posCashTransfer from "@src/views/apps/pos-cash-transfer/store";
import posEntry from "@src/views/apps/pos/store";
import inventoryMeasuringUnits from "@src/views/apps/inventory/measuring-units/store";
import inventorySeasons from "@src/views/apps/inventory/seasons/store";
import inventorySizes from "@src/views/apps/inventory/sizes/store";
import inventoryCategories from "@src/views/apps/inventory/category/store";
import inventorySubCategories from "@src/views/apps/inventory/sub-category/store";
import vendors from "@src/views/apps/purchases/vendors/store";
import employees from "@src/views/apps/employees/store";
import designations from "@src/views/apps/employees/definitions/designations/store";
import departments from "@src/views/apps/employees/definitions/departments/store";
import allowances from "@src/views/apps/employees/definitions/allowances/store";
import payAllowances from "@src/views/apps/pay-allowances/store";
import payDeductions from "@src/views/apps/pay-deductions/store";
import payLeaves from "@src/views/apps/pay-leaves/store";
import deductions from "@src/views/apps/employees/definitions/deductions/store";
import salesMan from "@src/views/apps/sales-man/store";
import customers from "@src/views/apps/sales/customers/store";
import costCenter from "@src/views/apps/cost-centers/store";
import fYear from "@src/views/apps/financial-year/store";
import receiving from "@src/views/apps/receiving/store";
import dataTables from "@src/views/tables/data-tables/store";
import permissions from "@src/views/apps/user-roles/store";
import issueToLocations from "@src/views/apps/stocks/issue-to-location/store";
import receiptInLocations from "@src/views/apps/stocks/receipt-in-location/store";
import openingStock from "@src/views/apps/stocks/opening-stock/store";
import profit from "@src/views/reports/profit-loss/store";
import projectProfit from "@src/views/reports/project-profit-loss/store";
import balanceSheet from "@src/views/reports/balance-sheet/store";
import trialBalance from "@src/views/reports/trail_balance/store";
import customerAdvance from "@src/views/reports/customerAdvance/store";
import aging from "@src/views/reports/aging/store";
import customerAging from "@src/views/reports/customer-aging/store";
import TaxCodeReport from "@src/views/reports/tax-code-report/store";
import TaxCodeDetailReport from "@src/views/reports/tax-code-report/tax-code-report-detail/store";
import saleInvoiceCustomization from "@src/views/apps/invoice-customization/store";
import saleExportInvoiceCustomization from "@src/views/apps/invoice-export-packing-customization/store";
import SalesPackingSlipCustomization from '@src/views/apps/saleInvoice-packingSlip-customization/store'
import SalesDeliveryNoteCustomization from '@src/views/apps/saleInvoice-deliveryNote-customization/store'
import saleInvoiceReturnCustomization from "@src/views/apps/invoice-customization-sale-return/store";
import purchaseInvoiceReturnCustomization from "@src/views/apps/invoice-customization-purchase-return/store";
import purchaseInvoiceCustomization from "@src/views/apps/invoice-customization-purchase/store";
import JVCustomization from "@src/views/apps/invoice-customization-jv/store";
import vendorAdvance from "@src/views/reports/vendorAdvance/store";
import vendorBalances from "@src/views/reports/vendorBalances/store";
import CustomerBalance from "@src/views/reports/CustomerBalance/store";
import payableSummary from "@src/views/reports/payableSummary/store";
import paymentMade from "@src/views/reports/paymentMade/store";
import expenseInvoiceCustomization from "@src/views/apps/invoice-customization-expense/store";
import poCustomization from "@src/views/apps/invoice-customization-po/store";
import prCustomization from "@src/views/apps/invoice-customization-pr/store";
import demandCustomization from "@src/views/apps/invoice-customization-demand/store";
import grnCustomization from "@src/views/apps/invoice-customization-grn/store";
import deliveryNotesCustomization from "@src/views/apps/invoice-customization-delivery-notes/store";
import itlCustomization from "@src/views/apps/invoice-customization-itl/store";
import rilCustomization from "@src/views/apps/invoice-customization-ril/store";
import grnReturnCustomization from "@src/views/apps/invoice-customization-grn-return/store";
import estimateCustomization from "@src/views/apps/invoice-customization-estimate/store";
import recoveriesCustomization from "@src/views/apps/invoice-customization-recoveries/store";
import paymentMadeCustomization from "@src/views/apps/invoice-customization-payment-made/store";
import stockReports from "@src/views/reports/stock-reports/store";
import singleItemReport from "@src/views/reports/single-item-ledger/store";
import itemValuation from "@src/views/reports/item-valuation-report/store";
import itemAdjustment from "@src/views/apps/inventory/item-adjustment/store";
import itemConsumption from "@src/views/apps/inventory/item-consumption/store";
import adjustmentTypes from "@src/views/apps/inventory/adjustment-type/store";
import favStore from "@src/views/apps/deshboard/store";
import templateStore from "@src/views/apps/templates/store";
import purchaseOrderPref from "@src/views/settings/company/form-view/steps/preferences/purchase-order/store";
import billsApprovals from "@src/views/settings/company/form-view/steps/approvals/billApprovals/store";
import inventoryFields from "@src/views/settings/company/form-view/steps/dynamic-fields/inventoryFields/store";
import customerInvoiceApprovals from "@src/views/settings/company/form-view/steps/approvals/customerInvoiceApprovals/store";
import jvApprovals from "@src/views/settings/company/form-view/steps/approvals/jvApprovals/store";
import expenseApprovals from "@src/views/settings/company/form-view/steps/approvals/expenseApprovals/store";
import grnPref from "@src/views/settings/company/form-view/steps/preferences/goods-received-note/store";
import deliveryNotePref from "@src/views/settings/company/form-view/steps/preferences/delivery-note/store";
import purchaseRequestPref from "@src/views/settings/company/form-view/steps/preferences/purchase-request/store";
import demandPref from "@src/views/settings/company/form-view/steps/preferences/demand/store";
import purchaseInvoicePref from "@src/views/settings/company/form-view/steps/preferences/vendor-invoice/store";
import customerInvoicePref from "@src/views/settings/company/form-view/steps/preferences/customer-invoice/store";
import purchaseReturnPref from "@src/views/settings/company/form-view/steps/preferences/purchase-return/store";
import salesReturnPref from "@src/views/settings/company/form-view/steps/preferences/sales-return/store";
import expensePreference from "@src/views/settings/company/form-view/steps/preferences/expense/store";
import estimatePref from "@src/views/settings/company/form-view/steps/preferences/estimate/store";
import customerPref from "@src/views/settings/company/form-view/steps/preferences/customer/store";
import vendorPref from "@src/views/settings/company/form-view/steps/preferences/vendor/store";
import customerInvoiceEmail from "@src/views/settings/company/form-view/steps/email/customer-invoice/store";
import taxesSettings from "@src/views/settings/company/form-view/steps/taxes/store";
import withHeldTax from "@src/views/settings/company/form-view/steps/taxes/wht/store"
import reportingTags from "@src/views/settings/company/form-view/steps/reporting-tags/store"
import advanceSettings from "@src/views/settings/company/form-view/steps/advance-settings/store";
import posSettings from "@src/views/settings/company/form-view/steps/pos-settings/store";
import PaymentReceived from "@src/views/reports/paymentReceived/store";
import creditNoteDetail from "@src/views/reports/creditNoteDetail/store";
import VendorCreditDetail from "@src/views/reports/vendorCreditDetail/store";
import InvoiceDetail from "@src/views/reports/invoice-detail/store";
import BillableDetail from "@src/views/reports/billable-detail/store";
import projectInvoiceDetail from "@src/views/reports/project-invoices/store";
import BillsDetail from "@src/views/reports/BillsDetail/store";
import branchBanks from "@src/components/LocationBankSelector/store";
import accountDetailSelector from "@src/components/AccountDetailSelector_v2/store";
import salesReport from "@src/views/reports/sales_report/store";
import purchaseReport from "@src/views/reports/purchase_report/store";
import ledgerReport from "@src/views/reports/general_ledger/store";
import bankLedgerReport from "@src/views/reports/bank_ledgers/store";
import subscriptionInformation from "@src/views/settings/subscriptions/store"
import tickets from "@src/views/tickets-system/store"
import customerPOSInvoice from "@src/views/apps/pos/form-view/tabs/invoices/store"
import projects from "@src/views/apps/projects/store"
import vatReturn from "@src/views/apps/vat-return/store"
import corporateTax from "@src/views/apps/vat-corporate/store"
import vatPayment from "@src/views/apps/vat-payment/store"
import corporatePayment from "@src/views/apps/vat-corporate-payment/store"
import brs from "@src/views/apps/cash-and-banks/store"
import budget from "@src/views/apps/budgets/store"
import payroll from "@src/views/apps/payroll/store"
import payrollPayment from "@src/views/apps/pay-payments/store"
import payPeriods from "@src/views/apps/pay-periods/store"
import projectTransactions from "@src/views/apps/projects/project-dashboard/detailed-cards/transactions/store"
import { combineReducers } from 'redux';


const rootReducer = {
   auth,
   notifications,
   coa,
   todo,
   users,
   navbar,
   layout,
   mapAccounts,
   invoice,
   demands,
   demand,
   purchaseRequests,
   customerInvoice,
   deliveryNotes,
   customerRecurrenceInvoice,
   customerEstimate,
   issueToLocations,
   receiptInLocations,
   journal,
   journalRecurrence,
   vendorInvoice,
   returnOutwards,
   returnInwards,
   purchaseOrders,
   taxTypes,
   areas,
   GRN,
   GRNReturn,
   payments,
   receiving,
   vendors,
   employees,
   designations,
   departments,
   allowances,
   payAllowances,
   payDeductions,
   payLeaves,
   deductions,
   salesMan,
   customers,
   costCenter,
   creditTerms,
   fYear,
   dataTables,
   permissions,
   inventoryItems,
   inventoryBrands,
   inventoryColors,
   inventoryCostCenters,
   inventoryDepartments,
   inventoryMeasuringUnits,
   inventorySeasons,
   inventorySizes,
   inventoryCategories,
   inventorySubCategories,
   TenantCurrencies,
   profit,
   projectProfit,
   balanceSheet,
   trialBalance,
   aging,
   customerAging,
   TaxCodeReport,
   TaxCodeDetailReport,
   saleInvoiceCustomization,
   SalesPackingSlipCustomization,
   SalesDeliveryNoteCustomization,
   saleInvoiceReturnCustomization,
   purchaseInvoiceCustomization,
   purchaseInvoiceReturnCustomization,
   expenseInvoiceCustomization,
   stockReports,
   singleItemReport,
   itemAdjustment,
   adjustmentTypes,
   favStore,
   poCustomization,
   prCustomization,
   demandCustomization,
   grnCustomization,
   deliveryNotesCustomization,
   itlCustomization,
   rilCustomization,
   openingStock,
   estimateCustomization,
   recoveriesCustomization,
   paymentMadeCustomization,
   grnReturnCustomization,
   saleExportInvoiceCustomization,
   templateStore,
   purchaseOrderPref,
   grnPref,
   deliveryNotePref,
   purchaseRequestPref,
   demandPref,
   purchaseInvoicePref,
   customerPref,
   vendorPref,
   customerInvoicePref,
   purchaseReturnPref,
   salesReturnPref,
   expense,
   expensePreference,
   estimatePref,
   customerInvoiceEmail,
   customerAdvance,
   vendorAdvance,
   vendorBalances,
   CustomerBalance,
   payableSummary,
   paymentMade,
   PaymentReceived,
   creditNoteDetail,
   VendorCreditDetail,
   InvoiceDetail,
   projectInvoiceDetail,
   BillsDetail,
   branchBanks,
   accountDetailSelector,
   JVCustomization,
   itemValuation,
   salesReport,
   purchaseReport,
   ledgerReport,
   bankLedgerReport,
   recurringBill,
   recurringExpense,
   fileManager,
   attachmentManager,
   posTills,
   posShifts,
   posCashTransfer,
   posEntry,
   subscriptionInformation,
   tickets,
   customerPOSInvoice,
   projects,
   vatReturn,
   vatPayment,
   corporatePayment,
   taxesSettings,
   advanceSettings,
   projectTransactions,
   brs,
   budget,
   payroll,
   payrollPayment,
   payPeriods,
   posSettings,
   BillableDetail,
   itemConsumption,
   billsApprovals,
   customerInvoiceApprovals,
   jvApprovals,
   expenseApprovals,
   corporateTax,
   withHeldTax,
   inventoryFields,
   reportingTags
};

const appReducer = combineReducers(rootReducer);

const rootReducers = (state, action) => {
   if (action.type === 'LOGOUT') {
      state = {
         mapAccounts: state.mapAccounts,
         navbar: state.navbar,
         layout: state.layout,
         auth: state.auth
      };
   }
   return appReducer(state, action);
};

export default rootReducers;
